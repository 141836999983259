<template>
  <Header />
  <div class="container-fluid">
    <router-view v-slot="{ Component }">
      <component :is="Component" :key="$route.fullPath" />
      <!-- <keep-alive> -->
        <!-- <component :is="Component" :key="$route.fullPath" /> -->
      <!-- </keep-alive> -->
    </router-view>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: "App",
  components: {
    Header
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.col-1 .btn, .col-form-label {
  min-width: 142px;
}

.col-4 .btn {
  min-width: 175px;
}

.col-2 {
  min-width: 240px;
}

.col-3, .col-6, .col-10 {
  min-width: 370px;
}

tbody, td, tfoot, th, thead, tr {
  max-width: 700px;
}
</style>
