<template>
  <div>
    <slot></slot>
    <div class="col-12 row row-cols-1 row-cols-md-3 g-4 mb-4">
      <div v-for="(item, index) in tableBody" :key="index" class="col">
        <div class="card">
          <div class="card-body">
            <h5 class="text-primary">
              <span v-html="item.title"/>
            </h5>
            <div class="card-text">
              <span v-html="item.content"/>
            </div>
          </div>
          <div v-if="item.footer" class="card-footer">
            <div>
              <span v-if="Array.isArray(item.footer)">
                <TableCell v-for="(actionItem, actionItemIndex) in item.footer" :key="actionItemIndex" :item="actionItem" class="me-2"/>
              </span>
              <span v-else>
                  <TableCell :item="item.footer" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableCell from '@/components/TableCell.vue'

export default {
  name: "CardTable",
  props: {
    tableBody: Array
  },
  components: {
    TableCell
  }
};
</script>
