<template>
    <input type="text" @click="getFilteredItems" @input="getFilteredItems" @blur="blurHide" v-model="search" :class="inputClass"/>
    <div class="item-list" v-show="showItemList && filteredItems.length > 0">
        <div class="item" v-for="(item, index) in filteredItems" :key="index" @click="selectItem(item)">
            {{item}}
        </div>
    </div>
</template>

<script>
export default {
    name: "Autocomplete",
    emits: ["autocompleteSelected"],
    data: function() {
        return {
            filteredItems: [],
            showItemList: false,
            search: ""
        }
    },
    props: {
        items: Array,
        inputClass: String,
        initValue: String,
        parentRef: Object
    },
    watch: {
        initValue: function(newValue, oldValue) {
            this.search = newValue || oldValue;
        }
    },
    methods: {
        getFilteredItems() {
            this.showItemList = true;
            this.filteredItems = this.search ? this.items.filter( item => item.indexOf(this.search) > 0).slice(0,10) : this.items.slice(0, 10);
        },
        selectItem(item) {
            this.showItemList = false;
            this.search = item;
            this.$emit("autocompleteSelected", item, this.parentRef);
        },
        blurHide() {
            if (this.search) {
                this.$emit("autocompleteSelected", this.search, this.parentRef);
            }
            setTimeout(() => { this.showItemList = false; }, 500);
        }
    }
}
</script>

<style scoped>
.item-list {
    position: absolute;
    z-index: 999;
    background: white;
    border: 1px solid #ced4da;
    border-radius: 3px;
    margin-top: 1px;
    width: 400px;
}

.item {
    padding: 4px 12px;
}

.item:hover {
    background-color: rgb(180, 175, 175);
    color: white;
    cursor: pointer;
}
</style>