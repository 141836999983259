<template>
  <span v-if="item === null">
  </span>
  <span v-else-if="item.type === 'button'">
      <button class="btn btn-primary btn-sm" :class="item.class" @click="item.click">
        <span v-html="item.name"/>
      </button>
  </span>
  <span v-else-if="item.type === 'buttonLink'">
      <button class="btn btn-link" :class="item.class" style="padding: 0;" @click="item.click">
        <span v-html="item.name"/>
      </button>
  </span>
  <span v-else-if="item.type === 'link'">
      <router-link :to="item.link" :class="item.class" class="text-decoration-none">{{item.name}}</router-link>
  </span>
  <span v-else-if="item.type === 'status'">
      <span :class="item.class">
        <span v-html="item.name"/>
      </span>
  </span>
  <span v-else v-html="item"/>
</template>

<script>
export default {
  name: 'TableCell',
  props: {
    item: [String, Number, Object, Array]
  }
}
</script>