import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Datepicker from 'vue3-datepicker'

// @ is an alias to /src
import BreadCrumb from '@/components/BreadCrumb.vue'
import Table from '@/components/Table.vue'
import CardTable from '@/components/CardTable.vue'
import Alert from '@/components/Alert.vue'
import Modal from '@/components/Modal.vue'
import Autocomplete from '@/components/Autocomplete'

createApp(App)
.component("BreadCrumb", BreadCrumb)
.component("Alert", Alert)
.component("Modal", Modal)
.component("Table", Table)
.component("CardTable", CardTable)
.component("Autocomplete", Autocomplete)
.component("Datepicker", Datepicker)
.use(store).use(router)
.mount('#app')
