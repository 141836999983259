<template>
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
        <li v-for="(item, index) in breadcrumbs" :key="index" class="breadcrumb-item" :class="{ active: index === breadcrumbs.length-1 }">
            <span v-if="item.link">
                <router-link :to="item.link">{{item.name}}</router-link>
            </span>
            <span v-else>
                {{item.name}}
            </span>
        </li>
    </ol>
</template>

<script>
export default {
    name: "BreadCrumb",
    props: {
        breadcrumbs: Array
    }
}
</script>