<template>
<form id="login">
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <div class="mb-3">
        <label class="control-label">Username</label>
        <input type="text" v-model="username" class="form-control" placeholder="Username" />
    </div>
    <div class="mb-3">
        <label class="control-label">Password</label>
        <input type="password" v-model="password" class="form-control" placeholder="Password" />
    </div>
    <button @click="login" class="btn btn-primary">Sign In</button>
</form>
</template>

<script>
import axios from 'axios';

//var systemUrl = "http://192.168.1.48:8080/";
var systemUrl = "/";

axios.defaults.headers.common['Authorization'] = localStorage.getItem('token') ? 'Bearer ' + localStorage.getItem('token') : "";
axios.defaults.baseURL = systemUrl + "api";
axios.defaults.timeout = 60000;

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response) {
        if (403 === error.response.status) {
            localStorage.removeItem("token");
            window.location = "/?#/login";
        } else if (500 === error.response.status) {
            return Promise.reject("Some data is malformed, please contact administrator to solve it.");
        } else if (400 === error.response.status) {
            return Promise.reject("Your request cannot be processed at this time!");
        } else {
            return Promise.reject(error);
        }
    } else {
        console.error(error);
        return Promise.reject("System is currently under maintenance.");
    }
})

export default {
  name: 'login',
  data: function() {
    return {
      username: "",
      password: "",
      submitted: false,
      alertContent: "",
      alertClass: "alert-danger"
    };
  },
  mounted() {
      if (localStorage.getItem("token")) {
          window.location = "/#/"
      }
      if (this.$route.href.startsWith("#")) {
          window.location = "/?#/login";
      }
  },
  methods: {
    login() {
      if (this.submitted) {
          return
      }
      this.submitted = true;
      localStorage.setItem("username", this.username);
      axios.post("/token", {
          username: this.username, 
          password: this.password
      }, { baseURL: systemUrl }).then(response => {
          localStorage.setItem("token", response.data.token);
          window.location = "/#/"
          this.submitted = false;
      }).catch(() => {
          this.alertContent = "The combination of username and password is incorrect!";
          this.alertClass = "alert-danger";
          this.submitted = false;
      })
    }
  }
};
</script>

<style scoped>
#login {
    text-align: left;
    width: 60%;
    margin-left: 20%;
    border: 1px solid #ced4da;
    border-radius: 3px;
    padding: 35px;
}
</style>
