import axios from "axios";
import { createRouter, createWebHashHistory } from "vue-router"
import Login from "../views/Login.vue"

var parseJwt = function (token) {
  if (token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(atob(base64).split("").map(function (c) {
      return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(""));

    return JSON.parse(jsonPayload);
  }
}

//var systemUrl = "http://192.168.1.48:8080/";
var systemUrl = "/";

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('../views/SignUp.vue')
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('../views/Order.vue')
  },
  {
    path: '/order/edit',
    name: 'OrderEdit',
    component: () => import('../views/OrderEdit.vue')
  },
  {
    path: '/order/batch',
    name: 'OrderBatch',
    component: () => import('../views/OrderBatch.vue')
  },
  {
    path: '/order/cancel',
    name: 'OrderCancel',
    component: () => import('../views/OrderCancel.vue')
  },
  {
    path: '/notification',
    name: 'Notification',
    component: () => import('../views/Notification.vue')
  },
  {
    path: '/notification/edit',
    name: 'NotificationEdit',
    component: () => import('../views/NotificationEdit.vue')
  },
  {
    path: '/notification/new',
    name: 'NotificationNew',
    component: () => import('../views/NotificationEdit.vue')
  },
  {
    path: '/giftcard',
    name: 'GiftCard',
    component: () => import('../views/GiftCard.vue')
  },
  {
    path: '/giftcard/edit',
    name: 'GiftCardEdit',
    component: () => import('../views/GiftCardEdit.vue')
  },
  {
    path: '/giftcard/new',
    name: 'GiftCardBatch',
    component: () => import('../views/GiftCardBatch.vue')
  },
  {
    path: '/giftcard/transaction',
    name: 'Transaction',
    component: () => import('../views/Transaction.vue')
  },
  {
    path: '/giftcard/transaction/new',
    name: 'TransactionNew',
    component: () => import('../views/TransactionEdit.vue')
  },
  {
    path: '/giftcard/transaction/Edit',
    name: 'TransactionEdit',
    component: () => import('../views/TransactionEdit.vue')
  },
  {
    path: '/bestbuy',
    name: 'BestBuy',
    component: () => import('../views/BestBuy.vue')
  },
  {
    path: '/',
    name: 'Deal',
    component: () => import('../views/Deal.vue')
  },
  {
    path: '/deal/edit',
    name: 'DealEdit',
    component: () => import('../views/DealEdit.vue')
  },
  {
    path: '/deal/new',
    name: 'DealNew',
    component: () => import('../views/DealEdit.vue')
  },
  {
    path: '/deal/import',
    name: 'DealImport',
    component: () => import('../views/DealImport.vue')
  },
  {
    path: '/creditcard',
    name: 'CreditCard',
    component: () => import('../views/CreditCard.vue')
  },
  {
    path: '/creditcard/edit',
    name: 'CreditCardEdit',
    component: () => import('../views/CreditCardEdit.vue')
  },
  {
    path: '/creditcard/new',
    name: 'CreditCardNew',
    component: () => import('../views/CreditCardEdit.vue')
  },
  {
    path: '/creditcard/bill',
    name: 'CreditCardBill',
    component: () => import('../views/CreditCardBill.vue')
  },
  {
    path: '/creditcard/bill/edit',
    name: 'CreditCardBillEdit',
    component: () => import('../views/CreditCardBillEdit.vue')
  },
  {
    path: '/creditcard/bill/new',
    name: 'CreditCardBillNew',
    component: () => import('../views/CreditCardBillEdit.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue')
  },
  {
    path: '/account/edit',
    name: 'AccountEdit',
    component: () => import('../views/AccountEdit.vue')
  },
  {
    path: '/account/new',
    name: 'AccountNew',
    component: () => import('../views/AccountEdit.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/package/edit',
    name: 'PackagEdit',
    component: () => import('../views/PackagEdit.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === "SignUp") {
    next();
  } else if (to.name !== "Login" && !localStorage.getItem("token")) {
    next({ name: "Login" });
  } else {
    var userInfo = parseJwt(localStorage.getItem("token"));
    if (userInfo) {
      localStorage.setItem("role", userInfo.auth);
    }
    if (userInfo && userInfo.exp && new Date(userInfo.exp * 1000) < new Date(new Date().getTime() + 600000)) {
      axios.get("/token/refresh", { baseURL: systemUrl })
        .then(response => {
          localStorage.setItem("token", response.data.token);
          axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.token;
          next();
        })
        .catch((error) => {
          console.error("Failed to refresh token!");
          console.error(error);
          localStorage.removeItem("token");
          localStorage.removeItem("username");
        });
    } else {
      next();
    }
  }
})

export default router
