<template>
  <slot></slot>
  <table class="table table-hover">
    <thead>
      <tr>
        <th v-for="(item, index) in tableHeader" :key="index">
          {{item}}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, index) in tableBody" :key="index">
        <td v-for="(item, index) in row" :key="index">
          <span v-if="Array.isArray(item)">
              <TableCell v-for="(actionItem, actionItemIndex) in item" :key="actionItemIndex" :item="actionItem" class="me-2"/>
          </span>
          <span v-else>
              <TableCell :item="item" />
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import TableCell from '@/components/TableCell.vue'

export default {
  name: 'Table',
  props: {
    tableHeader: Array,
    tableBody: Array
  },
  components: {
    TableCell
  }
}
</script>