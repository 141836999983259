<template>
    <div class="alert alert-dismissible fade show">
        <slot></slot>
        <button type="button" class="btn-close" @click="close"></button>
    </div>
</template>

<script>
export default {
    name: "Alert",
    methods: {
        close() {
            this.$emit('closeAlert');
        }
    }
}
</script>