<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-primary mb-4">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        CCDWJ
      </router-link>
      <button
        class="navbar-toggler navbar-toggler-right"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" v-if="visible">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <li class="nav-item dropdown" :class="currentRoute.path.startsWith('/deal') || currentRoute.path === '/' ? 'nav-item-active' : ''">
            <a class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              href="#">Deal<span class="caret"></span></a>
            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" to="/">Deal List</router-link></li>
              <li><router-link class="dropdown-item" to="/deal/new">Add Deal</router-link></li>
            </ul>
          </li>
          <li class="nav-item dropdown" :class="currentRoute.path.startsWith('/giftcard') ? 'nav-item-active' : ''">
            <a class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              href="#">Gift Card<span class="caret"></span></a>
            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" to="/giftcard">Gift Card List</router-link></li>
              <li><router-link class="dropdown-item" to="/giftcard/new">Add Gift Card</router-link></li>
              <div class="dropdown-divider"></div>
              <li><router-link class="dropdown-item" to="/giftcard/transaction">Transaction List</router-link></li>
              <li><router-link class="dropdown-item" to="/giftcard/transaction/new">Add Transaction</router-link></li>
            </ul>
          </li>
          <li class="nav-item dropdown" :class="currentRoute.path.startsWith('/creditcard') ? 'nav-item-active' : ''">
            <a class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              href="#">Credit Card<span class="caret"></span></a>
            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" to="/creditcard">Credit Card List</router-link></li>
              <li><router-link class="dropdown-item" to="/creditcard/new">Add Credit Card</router-link></li>
              <div class="dropdown-divider"></div>
              <li><router-link class="dropdown-item" to="/creditcard/bill">Bill List</router-link></li>
              <li><router-link class="dropdown-item" to="/creditcard/bill/new">Add Bill</router-link></li>
            </ul>
          </li>
          <li class="nav-item dropdown" :class="currentRoute.path.startsWith('/account') ? 'nav-item-active' : ''">
            <a class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              href="#">Account<span class="caret"></span></a>
            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" to="/account">Account List</router-link></li>
              <li><router-link class="dropdown-item" to="/account/new">Add Account</router-link></li>
            </ul>
          </li>
        </ul>
        <ul class="navbar-nav">
          <ul class="navbar-nav dropdown">
            <li v-if="internalUser" class="nav-item">
              <router-link class="nav-link" to="/bestbuy" :class="currentRoute.path.startsWith('/bestbuy') ? 'nav-item-active' : ''">Best Buy</router-link>
            </li>
            <li v-if="internalUser" class="nav-item dropdown" :class="currentRoute.path.startsWith('/order') ? 'nav-item-active' : ''">
              <a class="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                href="#">Order<span class="caret"></span></a>
              <ul class="dropdown-menu">
                <li><router-link class="dropdown-item" to="/order">Order List</router-link></li>
                <li><router-link class="dropdown-item" to="/order/batch">Order Claim</router-link></li>
                <li><router-link class="dropdown-item" to="/order/cancel">Order Canceled</router-link></li>
              </ul>
            </li>
            <li v-if="internalUser" class="nav-item dropdown" :class="currentRoute.path.startsWith('/notification') ? 'nav-item-active' : ''">
              <a class="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                href="#">Notification<span class="caret"></span></a>
              <ul class="dropdown-menu">
                <li><router-link class="dropdown-item" to="/notification">Notification List</router-link></li>
                <li><router-link class="dropdown-item" to="/notification/new">Add Notification</router-link></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-item-active"
                data-bs-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                href="#">Welcome, {{username}}<span class="caret"></span></a>
              <ul class="dropdown-menu dropdown-menu-end">
                <li><router-link class="dropdown-item" to="/profile">Profile</router-link></li>
                <li><a class="dropdown-item" v-on:click="logout" href="#">Logout</a></li>
              </ul>
            </li>
          </ul>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      currentRoute: {},
      visible: localStorage.getItem("token") && localStorage.getItem("token").length > 0,
      username: localStorage.getItem("username") || "user",
      internalUser: localStorage.getItem("role") === "ROLE_ADMIN"
    }
  },
  created() {
      this.currentRoute = this.$router.currentRoute;
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      window.location = "/?#/login";
    }
  }
}
</script>

<style scoped>
.nav-item-active {
  color: white !important;
  font-weight: 400;
}

.nav-item-active .dropdown-toggle {
  color: white !important;
  font-weight: 400;
}
</style>