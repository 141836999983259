<template>
<div>
<div class="modal fade show" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button type="button" class="btn-close" @click="close"></button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to save the changes?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="close">Close</button>
        <button type="button" class="btn btn-primary" @click="confirm">Save changes</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show"></div>
</div>
</template>

<script>
export default {
    name: "Modal",
    emits: ["closeModal", "confirmModal"],
    methods: {
        close() {
            this.$emit("closeModal");
        },
        confirm() {
            this.$emit("confirmModal");
        }
    }
}
</script>

<style scoped>
.show {
    display: block;
}
</style>